export default {
	computed: {
		brandingVars() {
			const branding = window.PPBranding;
			return branding ? {
				"--branding": 1,
				"--primary-button": branding.button_primary,
				"--primary-button-hover": branding.button_primary_hover,
			} : {};
		}
	}
};