<template>
	<div class="login-selection">
		<a href="/login/PP" class="apx-btn apx-btn--primary">{{$t('labels.logInWithPassportal')}}</a>
		<a :href="authGlobals.SSOLoginLink" class="apx-btn mt-10">{{$t('labels.logInWithNable')}}</a>
		<div class="sign-up">
			<a href="https://www.n-able.com/products/passportal/trial">{{$t('labels.signUpTrial')}}</a>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: 'Landing',
	computed: {
		...mapState(['authGlobals']),
	}
}
</script>
<style lang="scss">
@import "../../../sass/views/apex/apex.variables";
@import "../../../sass/views/apex/apex.mixins";
@import "../../../sass/views/apex/apex.form";

.main-wrapper > .apx-panel {
	padding: 90px 60px;
}

.login-selection {
	width: 100%;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	a {
		text-decoration: none;
	}
}

.sign-up {
	text-align: center;
	margin-top: 10px;
	width: 100%;
	max-width: 400px;
	a {
		text-decoration: none;
	}
}
</style>
