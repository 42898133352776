import Swal from "sweetalert2";
import { mapActions } from "vuex";

const config = {
	customClass: {
		container: "apex-ui-swal-container",
		popup: "apex-ui-swal"
	},
	showCancelButton: true,
	showConfirmButton: true,
};

export default {

	methods: {
		...mapActions(["sendResetPasswordRequest", "sendOrgKeyRequest"]),
		resetPassword(email) {
			Swal.fire(Object.assign(config, {
				title: `${this.$t("titles.forgotPassword")}?`,
				text: this.$t("labels.enterEmailAddress"),
				input: "email",
				inputValue: email,
				confirmButtonText: this.$t("labels.recoverPasswordButton"),
			})).then(result => {
				if (!result.value) return;

				this.preloader(this.$t("labels.preparePasswordRecovery"));

				this.sendResetPasswordRequest({ "email": result.value })
					.then(() => {
						AlertService.success(this.$t("labels.recoveryEmailSent"), "");
					});

			});
		},

		requestOrgKey(email) {
			Swal.fire(Object.assign(config, {
				title: this.$t("titles.requestOrgKey") + "?",
				text: this.$t("labels.requestOrgKey"),
				input: "email",
				inputValue: email,
				confirmButtonText: this.$t("titles.requestOrgKey"),
				showLoaderOnConfirm: true,
			})).then(result => {
				if (!result.value) return;

				this.preloader(this.$t("labels.prepareOrgKeyRecovery"));

				this.sendOrgKeyRequest({ "email": result.value })
					.then(() => {
						AlertService.success(this.$t("labels.recoveryEmailSent"), "");
					});
			})
			;
		},

		preloader(text) {
			Swal.fire({
				title: this.$t("titles.pleaseWait"),
				text,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				didOpen: () => {
					Swal.showLoading();
				}
			});
		}
	}
};