export default {
	en: {
		cannotRegister: "You cannot activate this account until your Administrator completes the account registration.",
		titles: {
			activation: "Account activation",
			orgDetails: "Organization details",
			enterOrgKey: "Enter your organization key",
			chooseOrgKey: "Create organization key",
			orgSuccess: "Organization Created Successfully",
			saveOrgKey: "Save your organization key",
			forgetOrgKey: "Forget me",
			requestOrgKey: "Request Organization Key",
			forgotPassword: "Forgot Password",
			pleaseWait: "Please Wait..."
		},
		labels: {
			back: "Back",
			next: "Next",
			orgKey: "Organization Key",
			activate: "Activate",
			createAccount: "Create Account",
			proccedToPassportal: "Proceed to Passportal",
			orgKeyRules: "Required length {min} to {max} characters",
			confirmOrganizationKey: "Confirm Organization Key",
			ssaPnAgreement: `I agree to <a href="${window.authGlobals.ssaLink}" target="_blank">Software Service Agreement</a> and the <a href="${window.authGlobals.pnLink}" target="_blank">Privacy Notice</a>`,
			rememberKey: "Remember organization key",
			forgetOrgKey: "This will remove all locally cached user data for N-able Passportal. This includes the Organization Key, which is currently held as a cookie.",
			requestOrgKey: "Formerly called passphrase. This will contact your organization administrators for the organization key. Please enter your email address:",
			enterEmailAddress: "Please enter your email address",
			recoverPasswordButton: "Start password recovery",
			recoveryEmailSent: "A recovery email has been sent!",
			preparePasswordRecovery: "Preparing Password recovery email...",
			prepareOrgKeyRecovery: "Preparing Organization Key request email...",
			learnMore: "Learn more",
			logInWithPassportal: "Log in with Passportal",
			logInWithNable: "Log in with N-able",
			accountNotSetup: "Account not setup",
			ssoBlockedP1: "You are required to create your N-able SSO account to access Passportal.",
			ssoBlockedP2: "Check the email address associated with this account to look for an invitation to continue setting up your account.",
			ssoBlockedP3: "Contact your admin if you need this email to be resent.",
			support: "Contact support",
			backToLogin: " Back to login page",
			signUpTrial: "Sign up for free",
			copyKey: "Copy Key",
			copySuccess: "Copied!",
		},
		popover: {
			orgDescription: "The organization key is something the first user creates, this is used as a part of the encryption key.<br>For more information please check out the KB article ",
			orgDescriptionLinkText: "What is an Organization key?",
			orgDescriptionEnd: "N-able does not know this key and will never ask for this key."
		},
		warnings: {
			cantChangeOrgKey: "<p><b>This key cannot be changed.</b> The organization's key is part of the encryption process and is intended to be shared with your internal Passportal users.</p><p>N-able keeps no record of this key if you lose this key your data is unrecoverable.</p>",
			rememberOrgKey: "<p>N-able has no access to your organization's key, do not forget this key.</p><p>N-able will never ask for your Organization Key.</p>",
		},
		errors: {
			emptyOrgKey: "Please enter your organization key",
			emptyEmail: "Please enter you email address",
			emptyPassword: "Please enter your password",
			createOrgKey: "Please create your organizations key",
			confirmOrgKey: "Confirmation doesn't match Organization key",
			orgKeyRules: "@:(labels.orgKeyRules)",
			emptyOrgAddress: "Please enter address",
			emptyOrgZip: "Please enter ZIP / Postal Code",
			emptyOrgCity: "Please enter City",
			emptyOrgState: "Please enter State",
			emptyOrgCountryId: "Please select Country",
			emptyAgreed: "To proceed you must agree Software Service Agreement and the Privacy Notice",
		}
	}
};
