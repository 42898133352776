<template>
	<div>
		<h2 class="apx-section-title">{{ $t('titles.orgDetails') }}</h2>

		<div class="apx-form-row">
			<v-input name="orgAddress" v-model="formData.orgAddress" label="Address"/>
		</div>
		<div class="apx-form-row">
			<v-input name="orgZip" v-model="formData.orgZip" label="ZIP / Postal Code"/>
		</div>
		<div class="apx-form-row">
			<v-input name="orgCity" v-model="formData.orgCity" label="City"/>
		</div>
		<div class="apx-form-row">
			<v-input name="orgState" v-model="formData.orgState" label="State / Province / Region"/>
		</div>
		<div class="apx-form-row">
			<v-select name="orgCountryId" v-model="formData.orgCountryId" :options="countries" label="Country"/>
		</div>

		<activation-footer>
			<template v-slot:buttons>
				<v-button btnStyle="primary" @click="nextStep">
					<span>{{ $t('labels.next') }}</span>
				</v-button>
			</template>
		</activation-footer>
	</div>
</template>

<script>
	import vInput from '../../../components/apex-ui/form-components/v-input.vue';
	import vSelect from '../../../components/apex-ui/form-components/v-select.vue';
	import vButton from '../../../components/apex-ui/form-components/v-button.vue';

	import activationFooter from './activation-footer.vue';

	import { mapState } from "vuex";
	import GlobalBus from "../../../eventBus";
	import { ucFirst } from "../../../helpers";

	export default {
		name: 'AuthenticationStep1',

		props: ['formData', 'btnNextClick'],

		components: {
			vInput,
			vSelect,
			vButton,
			activationFooter
		},

		computed: {
			...mapState(['authGlobals']),
			countries() {
				const countries = [];
				for (let value in this.authGlobals.countries) {
					let label = this.authGlobals.countries[value];
					countries.push({ value, label });
				}
				return countries
			},
		},

		methods: {
			nextStep() {
				if (this.validate()) {
					this.btnNextClick();
				}
			},
			validate() {
				const errors = {};
				['orgAddress', 'orgZip', 'orgCity', 'orgState', 'orgCountryId'].map(item => {
					if (this.formData.hasOwnProperty(item) && !this.formData[item]) {
						errors[item] = this.$t(`errors.empty${ucFirst(item)}`);
					}
				});

				GlobalBus.$emit('apexUI.validation', errors);

				return Object.keys(errors).length === 0;
			},
		}
	}
</script>