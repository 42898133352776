<template>
	<div>
		<div class="apx-form-row apx-form-row--full-width">
			<hr>
		</div>
		<div class="apx-form-row apx-form-row--inline justify-content-end">
			<slot name="buttons"></slot>
		</div>
		<div v-if="errorResponse">
			<div class="apx-form-row apx-form-row--full-width">
				<hr class="m-t-15">
			</div>
			<div class="footer-error-message">
				<div class="apx-field-error" v-text="errorResponse"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'AuthenticationFooter',

		props: ['errorResponse'],
	}
</script>
