import Vue from "vue";
import Vuex from 'vuex';

Vue.use(Vuex);
// This gets clientGlobals passed via JavaScript::put from the controller
const { authGlobals = {} } = window;

const axios = require('axios');
const route = window.route;
export default new Vuex.Store({
	state: {
		authGlobals
	},
	getters: {},
	mutations: {},
	actions: {
		activateAccount(content, data) {
			return axios.post(route('sso.activate'), data);
		},

		sendResetPasswordRequest(content, data) {
			return axios.post(route("login.request.passwordReset"), data);
		},

		sendOrgKeyRequest(content, data) {
			return axios.post(route("login.request.orgKey"), data);
		}
	}
});
