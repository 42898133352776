export default class SsoOrganizationActivation {
	constructor(data = {}) {
		this.code = data.code;
		this.post = data.orgZip;
		this.city = data.orgCity;
		this.agreed = data.agreed;
		this.region = data.orgState;
		this.address = data.orgAddress;
		this.country_id = data.orgCountryId.value;
		this.organizationKey = data.organizationKey;
		this.confirmOrganizationKey = data.confirmOrganizationKey;
		this.encryptVaultKeyPassword = data.encryptVaultKeyPassword;
	}
}