<template>
	<form ref="loginForm" :action="route('sso.authenticate')" method="post" class="apx-form apx-form-row--full-width">

		<div class="apx-form-row">
			<v-input type="password"
					 id="organizationKey"
					 class="mb-10"
					 name="organizationKey"
					 label="Organization Key"
					 placeholder="Enter your organization key"
					 v-model="formData.organizationKey"></v-input>
			<v-checkbox name="rememberMe" v-model="formData.rememberMe" :checked="true" :label="$t('labels.rememberKey')"/>
		</div>
		<div class="apx-form-row">
			<v-button id="loginBtn" class="w-100p mt-20" btnStyle="primary" :disabled="isSubmitting" @click.prevent="submit">
				<span v-if="isSubmitting" class="mr-5"><i class="apx-loading"></i></span>
				<span v-text="$t('labels.next')"></span>
			</v-button>
		</div>
		<a @click.prevent="requestOrgKey(formData.email)" class="mt-5 d-block tx-center" v-text="$t('titles.requestOrgKey')"></a>
		<input type="hidden" name="code" :value="code">
		<input type="hidden" name="state" :value="state">
	</form>
</template>

<script>
import vInput from '../../components/apex-ui/form-components/v-input.vue';
import vCheckbox from '../../components/apex-ui/form-components/v-checkbox.vue';
import vButton from '../../components/apex-ui/form-components/v-button.vue';
import vAlert from '../../components/apex-ui/v-alert.vue';
import BasicTooltip from '../../components/basic-tooltip.vue'

import GlobalBus from "../../eventBus";
import { mapState } from "vuex";
import accessRecovery from "./mixins/accessRecovery";
import { dotGet } from "../../helpers";

export default {
	name: 'Login',
	mixins: [accessRecovery],
	components: {
		vAlert,
		vInput,
		vButton,
		vCheckbox,
		BasicTooltip
	},

	data() {
		return {
			isSubmitting: false,
			formData: {
				organizationKey: null,
				rememberMe: 0
			}
		}
	},
	created() {
		this.error = this.authGlobals.error || null;
	},
	computed: {
		...mapState(['authGlobals']),
		code() {
			return dotGet(this, '$route.query.code', null);
		},
    state() {
      return dotGet(this, '$route.query.state', null);
    }
	},
	methods: {
		validate() {
			const errors = {};
			if (!this.formData.organizationKey) {
				errors.organizationKey = this.$t('errors.emptyOrgKey');
			}
			GlobalBus.$emit('apexUI.validation', errors);
			return Object.keys(errors).length === 0;
		},
		submit() {
			this.error = null;
			if (this.validate()) {
				this.isSubmitting = true;
				this.$refs.loginForm.submit();
			}
		}
	},
}
</script>
<style lang="scss">
@import "../../../sass/views/apex/apex.variables";
@import "../../../sass/views/apex/apex.mixins";
@import "../../../sass/views/apex/apex.form";

.main-wrapper {
	@if (--branding) {
		#loginBtn { @include branding }
	}
}

</style>