import { strSlug } from "../../../helpers";
import GlobalBus from "../../../eventBus";

export const commonAttributes = {
	props: {
		id: String,
		help: String,
		label: String,
		name: String,
		disabled: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		fieldId() {
			const randomId = Math.random().toString(36).replace(/[^a-z]+/g, '').slice(2, 12);
			return this.id || `${this.name ? strSlug(this.name) : randomId}Id`;
		},
	},
	methods: {
		hasSlot(name) {
			return this.$slots[name] || this.$scopedSlots[name];
		},
	}
};

export const hasModel = {
	props: ["value"],
	computed: {
		model: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			}
		},
	}
};

export const hasValidation = {
	data() {
		return {
			error: null
		}
	},
	mounted() {
		GlobalBus.$on('apexUI.validation', errors => {
			if (this.name && errors[this.name]) {
				this.error = errors[this.name];
			} else {
				this.error = null;
			}
		});
	}
}