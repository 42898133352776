import api from "!../../../../../../../../../root/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../../../../../../../../../root/node_modules/css-loader/dist/cjs.js??clonedRuleSet-20.use[1]!../../../../../../../../../root/node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../../../../../../../root/node_modules/laravel-mix/node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-20.use[2]!../../../../../../../../../root/node_modules/sass-loader/dist/cjs.js??clonedRuleSet-20.use[3]!../../../../../../../../../root/node_modules/vue-loader/lib/index.js??vue-loader-options!./basic-tooltip.vue?vue&type=style&index=0&id=5590a5e0&prod&lang=scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};