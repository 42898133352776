import {escapeHtml} from "../helpers";

export const Utils = {
	data() {
		return {
			confirmationDefaults: {
				icon: "warning",
				title: "Confirmation",
				text: "Are you sure?",
				allowEnterKey: false,
				buttonsStyling: false,
				allowEscapeKey: false,
				showCancelButton: true,
				allowOutsideClick: false,
				confirmButtonText: "Yes",
				customClass: {
					popup: "text-normal",
					cancelButton: "cancel confirmation btn btn-white",
					confirmButton: "activate confirmation btn btn-danger m-r-10"
				}
			},
		}
	},
	methods: {
		escapeHtml,
		isObject(val) {
			return Object.prototype.toString.call(val) === '[object Object]';
		},
		arraysEqual(a, b) {
			return a.length === b.length && a.every((v, i) => v === b[i]);
		},
		copyToClipboard(text) {
			if (text) {
				this.$copyText(text).then(() => {
					this.copied = true;
					setTimeout(() => this.copied = false, 3000);
				});
			}
		},
		alert(message, params = {}) {
			return Swal.fire(Object.assign({}, {
				icon: 'warning',
				html: message,
				timer: 3000,
				showConfirmButton: false,
				showCancelButton: true,
				buttonsStyling: false,
				focusCancel: false,
				cancelButtonText: "OK",
				customClass: {
					popup: "text-normal",
					cancelButton: "btn btn-white m-b-15 m-t-10"
				}
			}, params));
		},
		alertSuccess(message, params = {}) {
			return this.alert(message, Object.assign({}, {
				title: this.$t('labels.success') !== 'labels.success' ? this.$t('labels.success') : 'Success',
				html: message,
				type: 'success',
				timer: null,
			}, params))
		},
		alertError(message, params = {}) {
			return this.alert(message, Object.assign({}, {
				type: 'error',
				timer: null
			}, params))
		},
		warning(message, params = {}) {
			return this.alert(message, Object.assign({}, {
				type: 'warning',
				timer: null
			}, params))
		},
		confirm(message, showConfirmationDialog = true) {
			if (showConfirmationDialog === false) {
				return new Promise(resolve => resolve());
			}
			return Swal.fire(Object.assign({}, this.confirmationDefaults, {
				title: this.$t('labels.areYouSure') !== 'labels.areYouSure' ? this.$t('labels.areYouSure') : 'Are you sure?',
				text: message,
				confirmButtonText: this.$t('labels.yes') !== 'labels.yes' ? this.$t('labels.yes') : 'Yes',
			})).then(({ value }) => {
				return new Promise(resolve => {
					if (value === true) {
						resolve();
					}
				})
			});
		}
	}
};

export const VueServerTableOptions = {
	// Disable rowToggler in tables from vue-tables-2-premium
	showChildRowToggler: false,
	texts: {
		count: 'Showing {from} to {to} of {count} records|{count} records|{count} record'
	}
};