<template>
	<div class="form-group">
		<label :for="fieldId" :class="`apx-checkbox apx-form-label${error ? ' apx-has-error' : ''}`">
			<input type="checkbox"
				   :id="fieldId"
				   :name="name"
				   :value="formValue"
				   v-model="model"
				   :disabled="disabled"
				   @input="error = null"
				   :aria-describedby="`${fieldId}Help`">
			<span class="apx-checkbox__check"></span>
			<span class="apx-form-label apx-checkbox__label">
				<slot v-if="hasSlot('default')"></slot>
				<span v-else v-html="label"></span>
			</span>
		</label>
		<div class="apx-checkbox-sub-label"  v-if="hasSlot('subLabel')">
			<slot name="subLabel"></slot>
		</div>
		<small class="apx-field-error" v-if="error" v-html="error"></small>
		<small :id="`${fieldId}Help`" class="apx-field-help" v-else-if="help" v-html="help"></small>
	</div>
</template>
<script>
import { commonAttributes, hasModel, hasValidation } from "./mixins.js";

export default {
	mixins: [commonAttributes, hasModel, hasValidation],
	props: {
		checked: {
			type: Boolean,
			default: false
		},
		formValue: {
			type: String,
			default: '1'
		}
	},
	created() {
		if (this.checked) {
			this.model = true;
		}
	},
}

</script>

<style lang="scss">
@import "../../../../sass/views/apex/apex.variables";
@import "../../../../sass/views/apex/form-components/checkbox";
</style>