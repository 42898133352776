<template>
	<form ref="loginForm" :action="route('login.authenticate')"
	      method="post" class="apx-form apx-form-row--full-width" @submit.prevent="submit">
		<div class="apx-form-row">
			<v-input type="email"
			         name="email"
			         label="Username"
			         placeholder="Enter your email address"
			         v-model="formData.email"/>
		</div>
		<div class="apx-form-row">
			<v-input type="password"
			         name="password"
			         label="Password"
			         placeholder="Enter your password"
			         v-model="formData.password"></v-input>

			<a @click.prevent="resetPassword(formData.email)" href="#" class="pp-forgot mt-5 d-block">Forgot password?</a>
		</div>

		<div v-if="!orgKeyInCookie">
			<div class="apx-form-row">
				<v-input type="password"
				         id="organizationKey"
				         name="organizationKey"
				         label="Organization Key"
				         placeholder="Enter your organization key"
				         v-model="formData.organizationKey"></v-input>

				<a @click.prevent="requestOrgKey(formData.email)" href="#" class="mt-5 d-block">Request organization key</a>
			</div>

			<div class="apx-form-row">
				<v-checkbox name="rememberMe" v-model="formData.rememberMe" :label="$t('labels.rememberKey')"/>
			</div>
		</div>
		<div v-else class="pt-10 tx-center">
			<a tabindex="-1" :href="this.route('login.reset')">Forget My Organization Key</a>
			<basic-tooltip class="d-inline-block"
			               :title="$t('titles.forgetOrgKey')"
			               class-overrides="tx-left"
			               :description="$t('labels.forgetOrgKey')"></basic-tooltip>
		</div>
		
		<v-button type="submit" id="loginBtn" class="w-100p mt-20" btnStyle="primary" :disabled="isSubmitting">
			<span v-if="isSubmitting" class="mr-5"><i class="apx-loading"></i></span>
			<span>Next</span>
		</v-button>

		<v-button type="button" id="backBtn" class="w-100p mt-20" btnStyle="secondary" :disabled="isSubmitting" @click="back()">
			<span>Back</span>
		</v-button>

		<a :href="authGlobals.supportLink" target="_blank" class="mt-20 d-block">Need help?</a>
	</form>
</template>

<script>
import vInput from '../../components/apex-ui/form-components/v-input.vue';
import vCheckbox from '../../components/apex-ui/form-components/v-checkbox.vue';
import vButton from '../../components/apex-ui/form-components/v-button.vue';
import vAlert from '../../components/apex-ui/v-alert.vue';
import BasicTooltip from '../../components/basic-tooltip.vue'

import GlobalBus from "../../eventBus";
import { mapState } from "vuex";
import {toBoolean} from "../../helpers";
import accessRecovery from "./mixins/accessRecovery";

export default {
	name: 'Login',
	mixins: [accessRecovery],
	components: {
		vAlert,
		vInput,
		vButton,
		vCheckbox,
		BasicTooltip
	},

	data() {
		return {
			isSubmitting: false,
			formData: {
				email: null,
				password: null,
				organizationKey: null,
				rememberMe: 0
			}
		}
	},
	computed: {
		...mapState(['authGlobals']),
		orgKeyInCookie () {
			return toBoolean(this.authGlobals.rememberMe);
		},
		email () {
			try {
				return atob(this.authGlobals.email);
			} catch (e) { }
			return '';
		}
	},
	methods: {
		validate() {
			const errors = {};
			if (!this.formData.email) {
				errors.email = this.$t('errors.emptyEmail');
			}
			if (!this.formData.password) {
				errors.password = this.$t('errors.emptyPassword');
			}
			if (!this.orgKeyInCookie && !this.formData.organizationKey) {
				errors.organizationKey = this.$t('errors.emptyOrgKey');
			}
			GlobalBus.$emit('apexUI.validation', errors);
			return Object.keys(errors).length === 0;
		},
		submit() {
			this.error = null;
			if (this.validate()) {
				this.isSubmitting = true;
				this.$refs.loginForm.submit();
			}
		},
		back() {
			this.$router.push({name: 'login-selection'});
		}
	},

	mounted() {
		this.formData.email = this.email;
		this.formData.rememberMe = this.authGlobals.rememberMe;
	}
}
</script>
<style lang="scss">

@import "../../../sass/views/apex/apex.variables";
@import "../../../sass/views/apex/apex.mixins";
@import "../../../sass/views/apex/apex.form";

.main-wrapper {

	// Branding
	@if (--branding) {
		#loginBtn { @include branding }
	}
}
</style>