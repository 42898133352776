<template>
	<div class="sso-blocked">
		<div role="alert" class="alert alert-warning text-center">
			<i class="fa fa-exclamation-triangle"></i>
			{{$t('labels.accountNotSetup')}}
		</div>
		<p>
			{{$t('labels.ssoBlockedP1')}}
		</p>
		<p>
			{{$t('labels.ssoBlockedP2')}}
		</p>
		<p>
			{{$t('labels.ssoBlockedP3')}}
		</p>
		<a :href="authGlobals.supportLink" target="_blank" class="apx-btn apx-btn--primary">{{$t('labels.support')}}</a>
		<a :href="route('login.selection')" class="apx-btn mt-10">{{$t('labels.backToLogin')}}</a>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "SsoBlockedTemplate",
	computed: {
		...mapState(['authGlobals']),
	}
}
</script>
<style lang="scss">
@import "../../../sass/views/apex/apex.variables";

.sso-blocked {
	a {
		text-decoration: none;
	}
}
</style>