import Vue from 'vue';
import VueI18n from "vue-i18n";
import VueClipboard from "vue-clipboard2";
import VTooltip from "v-tooltip";

import store from './views/authentication/providers/store';
import router from './views/authentication/providers/router';
import messages from "./views/authentication/providers/messages";
import AuthenticationApp from './views/authentication/index.vue';

// import GlobalBus from "./eventBus";
// import { dotGet } from "./helpers";
import { Utils } from "./mixins/utils";

// Vue.prototype.$constants = constants && constants.clients && constants.clients.clientConstants;

Vue.use(VTooltip, {
	defaultTemplate: '<div class="vue-tooltip m-b-5" role="tooltip"><div class="tooltip-inner"></div></div>'
});
Vue.use(VueI18n);
Vue.use(VueClipboard);

Vue.mixin(Utils);
// Vue.mixin(clientMixins);
// Vue.mixin(axiosResponseHandler);
Vue.mixin({
	methods: {
		route: window.route,
	}
});

const i18n = new VueI18n({
	locale: "en",
	fallbackLocale: "en",
	messages,
});

new Vue({
	el: '#authenticationApp',
	i18n,
	store,
	router,
	components: {
		AuthenticationApp,
	},
});