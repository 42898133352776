<template>
	<v-popover popoverClass="pp-basic-tooltip" popoverBaseClass="pp-tooltip pp-popover">
		<!-- This will be the popover target (for the events and position) -->
		<i class="fa fa-info-circle pp-tooltip-icon tooltip-target ic_message_information"></i>
		<!-- This will be the content of the popover -->
		<div slot="popover" :class="classOverrides">
			<strong>{{ title }}</strong> <hr>
			{{ description }}
		</div>
	</v-popover>
</template>

<script>
import {VPopover} from 'v-tooltip';

export default {
	props: {
		title: String,
		description: String,
		classOverrides: String
	},
	components: {
		VPopover
	},
	data () {
		return {
			isShown: false,
		}
	},

	methods: {

		toggle () {
			this.$root.$emit('hide');
			this.isShown = !this.isShown;
		}

	},

	mounted () {
		this.$root.$on('hide', _ => {
			this.isShown = false
		});
	}
}
</script>

<style lang="scss">

.pp-tooltip-icon {
	font-size: 14px;
	color: #86C349 !important;
	cursor: pointer;
}
$color: #f9f9f9;
$bd_color: #c4c4c4;
.pp-tooltip {
	display: block !important;
	z-index: 10000;
	background: $color;
	font-size: 12px;

	.tooltip-inner {
		color: white;
		border-radius: 16px;
		padding: 5px 10px 4px;
		line-height: 20px;
		hr {
			margin-top: 10px;
			margin-bottom: 15px;
		}
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		z-index: 1;
	}

	&[x-placement^="top"] {
		margin-bottom: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="bottom"] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^="right"] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^="left"] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&.pp-popover {

		background-color: #fff;
		background-clip: padding-box;
		border: 1px solid #ccc;
		border-radius: 6px;
		box-shadow: 0 5px 30px rgba(black, .1);

		.popover-inner {
			background: $color;
			color: #444444;
			padding: 24px;
			border-radius: 5px;
			box-shadow: 0 5px 30px rgba(black, .1);
		}

		.popover-arrow {
			border-color: $bd_color;
		}
	}

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity .15s, visibility .15s;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity .15s;
	}
}
</style>