<template>
	<div class="form-group">
		<label :for="fieldId" v-if="label" class="apx-form-label apx-input__label">
			{{ label }}
			<slot name="afterLabel"></slot>
		</label>
		<div :class="`apx-input-wrapper${error ? ' apx-has-error' : ''}`">
			<input :type="isPasswordVisible ? 'text' : fieldType" :id="fieldId"
				   ref="input"
				   :name="name"
				   v-model="model"
				   :class="fieldClass"
				   :disabled="disabled"
				   @input="error = null"
				   :readonly="readonly"
				   :placeholder="placeholder"
				   :aria-describedby="`${fieldId}Help`">
			<slot name="buttons"></slot>
			<button v-if="isPassword" type="button" class="apx-input__show-password-btn" @click="togglePassword">
				<i :class="`${showPassword ? 'ic_hide' : 'ic_show'}`"></i>
			</button>
			<i class="apx-error-icon ic_status_critical" v-if="error"></i>
		</div>
		<div>
			<small :class="helpLeftClass" v-if="helpLeft" v-html="helpLeft"></small>
			<small :class="`apx-field-error${helpLeft ? ' pull-right' : ''}`"  v-if="error" v-html="error"></small>
			<small :id="`${fieldId}Help`" :class="`apx-field-help${helpLeft ? ' pull-right' : ''}`" v-else-if="help" v-html="help"></small>
		</div>
	</div>
</template>
<script>
import { commonAttributes, hasModel, hasValidation } from "./mixins.js";

export default {
	mixins: [commonAttributes, hasModel, hasValidation],
	props: {
		type: String,
		readonly: {
			type: Boolean,
			default: false
		},
		placeholder: String,
		helpLeft: String,
		helpLeftClass: String,
	},
	data() {
		return {
			showPassword: false
		}
	},
	computed: {
		isPassword() {
			return this.fieldType === 'password';
		},
		isPasswordVisible() {
			return this.isPassword && this.showPassword;
		},
		fieldClass() {
			return `apx-input${this.isPassword ? ' apx-input__password' : ''}`;
		},
		fieldType() {
			return ['text', 'password', 'email'].includes(this.type) ? this.type : 'text';
		},
	},
	methods: {
		togglePassword() {
			this.showPassword = !this.showPassword;
		}
	},
}

</script>

<style lang="scss">
@import "../../../../sass/views/apex/apex.variables";
@import "../../../../sass/views/apex/form-components/input";
</style>