<template>
	<div :id="id" :class="alertClass" v-if="showing">
		<i v-if="hasIcon" :class="iconClass"></i>
		<div class="apx-alert__text">
			<slot></slot>
		</div>
		<i class="apx-alert__close ic_close" v-if="dismissible" @click="dismiss"></i>
	</div>
</template>
<script>

export default {
	props: {
		id: String,
		type: String,
		closeRedirect: String,
		hasIcon: {
			type: Boolean,
			default: true
		},
		dismissible: {
			type: Boolean,
			default: true
		},
	},
	data() {
		return {
			showing: true,
			dismissing: false,
		}
	},
	computed: {
		iconClass() {
			const classMap = {
				error: 'ic_status_warning',
				warning: 'ic_status_critical',
				success: 'ic_message_ok',
				information: 'ic_message_information',
			}
			return `apx-alert__icon ${classMap[this.alertType]}`;
		},
		alertType() {
			return ['success', 'warning', 'error', 'information'].includes(this.type) ? this.type : 'information';
		},
		alertClass() {
			return `apx-alert apx-alert--${this.alertType}${this.dismissing ? ' apx-alert--dismissing' : ''}`;
		}
	},
	methods: {
		dismiss() {
			this.dismissing = true;
			setTimeout(() => {
				if(this.closeRedirect) {
					window.location.replace(this.closeRedirect);
				}
				this.showing = false;
				this.dismissing = false;
			}, 200);
		}
	}
}

</script>

<style lang="scss">
@import "../../../sass/views/apex/apex.variables";
@import "../../../sass/views/apex/apex.alerts";
</style>