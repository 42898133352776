/**
 * Toggles Sidebar.
 */

import he from 'he/he';
export const toggleSideBar = (value) => {
	const toggleClass = "page-right-sidebar-collapsed";
	const container = document.getElementById("page-container");
	const actionMap = {
		open: "remove",
		close: "add"
	};
	const defaultAction = container.classList.contains(toggleClass) ? "open" : "close";
	// Need to get the proper action based on action mapping
	let action = actionMap[value] || actionMap[defaultAction];
	// but we need to make sure that the action is a valid function
	// to avoid throwing exception
	if (typeof container.classList[action] === "function") {
		container.classList[action](toggleClass);
	}
};

/**
 * Returns the value from a nested object
 * @param obj the actual object
 * @param dot dot notated name eg. main.level1.level2.level3
 * @param _default default value if ever there's no such property
 * @returns {*}
 */
export const dotGet = (obj, dot, _default) => {
	try {
		let path = dot.split(".");
		for (let i = 0; i < path.length; i++) {
			if (_default != null && i + 1 === path.length) {
				obj[path[i]] = obj[path[i]] || _default;
			}
			obj = obj[path[i]];
		}
		return (typeof obj === "undefined") ? _default : obj;

	} catch (e) {
		return _default;
	}
};

export const escapeHtml = (str) => {
	const map = {
		"&": "&amp;",
		"<": "&lt;",
		">": "&gt;",
		"\"": "&quot;",
		"'": "&#039;"
	};

	return String(str).replace(/[&<>"']/g, char => map[char]);
};

/**
 * Returns whether or not the value is empty
 * @param value
 * @returns {boolean}
 */
export const isEmpty = (value) => {
	return value === null ||
		typeof value === "undefined" ||
		(value.hasOwnProperty("length") && value.length === 0) ||
		(value.constructor === Object && Object.keys(value).length === 0)
		;
};

export const toJsonTree = (list, options) => {
	options = options || {};
	const _id_key = options.id_key || "id";
	const _parent_key = options.parent_key || "parent";
	const _children_key = options.children_key || "children";

	let tree       = [],
		childrenOf = {};
	let item, id, parentId;

	for (let i = 0, length = list.length; i < length; i++) {
		item = list[i];
		id = item[_id_key];
		parentId = item[_parent_key] || 0;
		// every item may have children
		childrenOf[id] = childrenOf[id] || [];
		// init its children
		item[_children_key] = childrenOf[id];
		if (parentId !== 0) {
			// init its parent's children object
			childrenOf[parentId] = childrenOf[parentId] || [];
			// push it into its parent's children object
			childrenOf[parentId].push(item);
		} else {
			tree.push(item);
		}
	}

	return tree;
};

export const ucFirst = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toInt = (str) => {
	const val = parseInt(str);
	return isNaN(val) ? 0 : val;
};

export const toBoolean = (value) => {
	if (value === "false" || isEmpty(value)) {
		return false;
	}
	return !!(value && (value === true || value === "true" || parseInt(value, 10) > 0 || value.length > 0));
};

export const htmlDecode = (input) => {
	if (input) {
		return he.decode(input);
	}
};

export const decode = (value) => {
	let tbdata = {};
	let container = document.createElement('div');
	if (typeof(value) == 'object') {
		if (value.clientname) {
			container.innerHTML = value.clientname;
			tbdata.clientname = htmlDecode(container.textContent);
		}
		if (value.description) {
			tbdata.pre = 'Description: ';
			container.innerHTML = value.description;
			let a = container.querySelector('a');
			if (a) {
				tbdata.description = htmlDecode(a.innerText);
				tbdata.href = a.href;
				tbdata.className = a.className;
				tbdata.link = true;
			} else {
				tbdata.description = htmlDecode(a.innerText);
				tbdata.pre = '';
				tbdata.link = false;
			}
		}
		if (value.username) {
			tbdata.username = 'Username: ';
			container.innerHTML = value.username;
			tbdata.username += htmlDecode(container.textContent);
		}
	} else {
		container.innerHTML = value;
		let a = container.querySelector('a');
		if (a) {
			tbdata.description = htmlDecode(a.innerText);
			tbdata.href = a.href;
			tbdata.className = a.className;
			tbdata.link = true;
		} else {
			tbdata.description = htmlDecode(container.textContent);
		}
	}
	return tbdata;
};

export const strSlug = (str) => {
	str = str.replace(/^\s+|\s+$/g, '');

	// Make the string lowercase
	str = str.toLowerCase();

	// Remove accents, swap ñ for n, etc
	const from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
	const to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
	for (let i = 0, l = from.length; i < l; i++) {
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	// Remove invalid chars
	str = str.replace(/[^a-z0-9 -]/g, '')
		// Collapse whitespace and replace by -
			 .replace(/\s+/g, '-')
		// Collapse dashes
			 .replace(/-+/g, '-');

	return str;
};

export const isValidDomainName = (str) => {
	let regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);

	if (isEmpty(str)) {
		return false;
	}
	return regex.test(str) === true;
};


/**
 * Highlights different types of characters in a password.
 *
 * This function takes a password string and wraps different types of characters
 * (uppercase letters, lowercase letters, numbers, and special characters) in
 * corresponding HTML span elements with specific classes for styling.
 *
 * @param {string} passwordData - The password string to be processed.
 * @returns {string} - The processed password string with HTML span elements.
 */
export const passwordVisibility = (passwordData) => {
	const regexForUpperCaseLetters = new RegExp(/[A-Z]/);
	const regexForLowerCaseLetters = new RegExp(/[a-z]/);
	const regexForNumbers = new RegExp(/[0-9]/);
	const regexForSpecialChars = new RegExp(/[^0-9A-Za-z]/);


	return passwordData.split('').map(char => {
		if (regexForSpecialChars.test(char)) {
			return `<span class="special-char">${char}</span>`;
		} else if (regexForUpperCaseLetters.test(char)) {
			return `<span class="upper-case">${char}</span>`;
		} else if (regexForLowerCaseLetters.test(char)) {
			return `<span class="lower-case">${char}</span>`;
		} else if (regexForNumbers.test(char)) {
			return `<span class="number-char">${char}</span>`;
		}
	}).join('');
}
