<template>
	<div>
		<h2 class="apx-section-title">{{ $t('titles.chooseOrgKey') }}</h2>
		<v-alert type="warning" :dismissible="false" class="m-b-10">
			<span v-html="$t('warnings.cantChangeOrgKey')"></span>
		</v-alert>

		<div class="apx-form-row">
			<v-input type="password"
				name="organizationKey"
				:label="$t('labels.orgKey')"
				v-model="formData.organizationKey"
				:helpLeft="`${organizationKeyLength} / ${orgKeyRules.MAX_LEN}`"
				:helpLeftClass="getHelpLeftClass(organizationKeyLength)"
				:help="$t('labels.orgKeyRules', { min: orgKeyRules.MIN_LEN, max: orgKeyRules.MAX_LEN} )">

				<template v-slot:afterLabel>
					<v-popover popoverBaseClass="pp-tooltip pp-popover" placement="right-start">
						<i class="fa fa-info-circle pp-tooltip-icon tooltip-target ic_message_information"></i>
						<div slot="popover">
							<strong>{{ $t('labels.orgKey') }}</strong>
							<hr>
							<div class="pp-popover-description">
								<p>
									<span v-html="$t('popover.orgDescription')"></span>
									<a href="https://documentation.n-able.com/passportal/userguide/Content/faqs/faq_org_key.htm" target="_blank">{{ $t('popover.orgDescriptionLinkText') }}</a>
								</p>
								<p>
									{{ $t('popover.orgDescriptionEnd') }}
								</p>
							</div>
						</div>
					</v-popover>
				</template>
			</v-input>
		</div>

		<div class="apx-form-row">
			<v-input type="password"
				name="confirmOrganizationKey"
				:label="$t('labels.confirmOrganizationKey')"
				v-model="formData.confirmOrganizationKey"
				:helpLeft="`${confirmOrganizationKeyLength} / ${orgKeyRules.MAX_LEN}`"
				:helpLeftClass="getHelpLeftClass(confirmOrganizationKeyLength)"
				/>
		</div>
		
		<div class="apx-form-row mt-30">
			<v-checkbox name="agreed" v-model="formData.agreed" :label="$t('labels.ssaPnAgreement')"/>
		</div>

		<activation-footer :errorResponse="errorResponse">
			<template v-slot:buttons>
				<v-button btnStyle="link" v-if="!isSubmitting" @click="btnPrevClick" class="m-r-5">
					<span>{{ $t('labels.back') }}</span>
				</v-button>
				<v-button btnStyle="primary" :disabled="isSubmitting" @click="nextStep">
					<span v-if="isSubmitting" class="mr-5"><i class="apx-loading"></i></span>
					<span>{{ $t('labels.createAccount') }}</span>
				</v-button>
			</template>
		</activation-footer>
	</div>
</template>

<script>
	import { VPopover } from 'v-tooltip';
	import vAlert from '../../../components/apex-ui/v-alert.vue';
	import vInput from '../../../components/apex-ui/form-components/v-input.vue';
	import vCheckbox from '../../../components/apex-ui/form-components/v-checkbox.vue';
	import vButton from '../../../components/apex-ui/form-components/v-button.vue';

	import activationFooter from './activation-footer.vue';

	import GlobalBus from "../../../eventBus";
	import { mapActions } from "vuex";
	import { orgKeyRules } from "../providers/constants";
	import SsoOrganizationActivation from "../../../Model/SsoOrganizationActivation";

	export default {
		name: 'AuthenticationStep2',

		props: ['formData', 'btnNextClick', 'btnPrevClick'],

		components: {
			VPopover,
			vAlert,
			vInput,
			vCheckbox,
			vButton,
			activationFooter
		},

		data() {
			return {
				isSubmitting: false,
				errorResponse: null,
			}
		},

		computed: {
			orgKeyRules() {
				return orgKeyRules;
			},
			organizationKeyLength() {
				return this.formData.organizationKey?.length ?? 0;
			},
			confirmOrganizationKeyLength() {
				return this.formData.confirmOrganizationKey?.length ?? 0;
			}
		},

		methods: {
			...mapActions(['activateAccount']),
			isLenghtValid(value) {
				return value >= orgKeyRules.MIN_LEN && value <= orgKeyRules.MAX_LEN;
			},
			getHelpLeftClass(value) {
				return this.isLenghtValid(value) ? 'text-success' : '';
			},
			validate() {
				const errors = {};
				if (!this.formData.agreed) {
					errors.agreed = this.$t('errors.emptyAgreed');
				}
				if (!this.formData.organizationKey) {
					errors.confirmOrganizationKey = null;
					errors.organizationKey = this.$t('errors.createOrgKey');
				} else if (this.formData.organizationKey.length < orgKeyRules.MIN_LEN || this.formData.organizationKey.length > orgKeyRules.MAX_LEN) {
					errors.confirmOrganizationKey = null;
					errors.organizationKey = this.$t('errors.orgKeyRules', { min: orgKeyRules.MIN_LEN, max: orgKeyRules.MAX_LEN });
				} else if (this.formData.organizationKey !== this.formData.confirmOrganizationKey) {
					errors.confirmOrganizationKey = this.$t('errors.confirmOrgKey');
				}

				GlobalBus.$emit('apexUI.validation', errors);

				return Object.keys(errors).length === 0;
			},
			nextStep() {
				const that = this;
				this.errorResponse = null;
				if (this.validate()) {
					this.isSubmitting = true;
					this.activateAccount(new SsoOrganizationActivation(this.formData))
						.then(({ data: { redirectUrl = null } }) => {
							if (redirectUrl) {
								that.$emit('updateRedirectUrl', redirectUrl);
							}
							that.btnNextClick();
						})
						.catch(({ response: { data: { message = '' } } = {} }) => {
							this.errorResponse = message;
						})
						.finally(() => this.isSubmitting = false);
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.v-popover {
		display: inline-block;
		vertical-align: middle;
	}

	.pp-popover {
		&-description {
			text-align: left;
		}
	}

	.apx-alert {
		max-width: none;
		align-items: center;
	}

	.apx-alert::v-deep(.apx-alert__text) {
		text-align: unset;
	}
</style>