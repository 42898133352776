<template>
	<div>
		<h2 class="apx-section-title">
			<i class="ic_message_ok text-success valign-middle"></i>
			<span class="valign-middle"> {{ $t('titles.orgSuccess') }}</span>
		</h2>

		<div class="apx-form-row apx-form-row--full-width">
			<hr class="m-t-15">
		</div>

		<h2 class="apx-section-title">{{ $t('titles.saveOrgKey') }}</h2>

		<v-alert type="warning" :dismissible="false" class="m-b-10">
			<span v-html="$t('warnings.rememberOrgKey')"></span>
		</v-alert>

		<v-input type="password"
			name="organizationKey"
			class="m-b-0"
			:label="$t('labels.orgKey')"
			:disabled="true"
			v-model="formData.organizationKey">

			<template v-slot:buttons>
				<button type="button"
					class="apx-input__show-password-btn"
					@click="copyToClipboard"
					v-tooltip="$t('labels.copyKey')">

					<i class="ic_copy"></i>
				</button>
			</template>
		</v-input>
		<small class="apx-field-help pull-right text-success m-r-35" v-if="copied">{{ $t('labels.copySuccess') }}</small>

		<activation-footer>
			<template v-slot:buttons>
				<a :href="redirectUrl" class="apx-btn apx-btn--link">{{ $t('labels.proccedToPassportal') }}</a>
			</template>
		</activation-footer>
	</div>
</template>

<script>
	import vInput from '../../../components/apex-ui/form-components/v-input.vue';
	import vAlert from '../../../components/apex-ui/v-alert.vue';

	import activationFooter from './activation-footer.vue';

	export default {
		name: 'AuthenticationStep3',

		props: ['formData', 'redirectUrl'],

		components: {
			vInput,
			vAlert,
			activationFooter
		},

		data() {
			return {
				copied: false
			}
		},

		methods: {
			copyToClipboard() {
				this.$copyText(this.formData.organizationKey).then(() => {
					this.copied = true;
					setTimeout(() => this.copied = false, 3000);
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.apx-alert {
		max-width: none;
		align-items: center;
	}

	.apx-alert::v-deep(.apx-alert__text) {
		text-align: unset;
	}
</style>