<template>
	<div class="apx-logo"></div>
</template>

<script>
import {dotGet} from "../../helpers";

export default {
	props: {
		src: String,
		alt: String
	},
	computed: {
		alternate () {
			return this.alt || 'logo';
		},
		defaultSrc () {
			return dotGet(window.PPBranding, 'logo') ?? dotGet(window.PPBranding,'defaultLogo');
		}
	},
	methods: {
		load () {
			let container = document.querySelector('.apx-logo');
			let image = new Image();
			image.src = this.src ?? this.defaultSrc;
			image.onload = () => {
				if (image.complete && image.naturalWidth > container.clientWidth) {
					image.style.maxWidth = '100px';
				}
			};
			image.onerror = () => {
				image.src = this.defaultSrc;
			};
			container.appendChild(image);
		}
	},
	mounted() {
		this.load();
	}
}
</script>

<style lang="scss">
.apx-logo {
	margin-bottom: 20px;
	text-align: center;
}
</style>