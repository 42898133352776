<template>
	<button :id="id"
			:class="fieldClass"
			:type="btnType"
			:value="value"
			:disabled="disabled"
			v-on="$listeners">
		<slot v-if="hasSlot('default')"></slot>
		<span v-else v-text="label"></span>
	</button>
</template>
<script>
import { commonAttributes } from "./mixins.js";

export default {
	mixins: [commonAttributes],
	props: {
		type: String, // 'primary', 'disabled', 'danger', 'link' can be used, which will add apx-btn--primary,... css class
		value: String,
		btnStyle: String,
	},
	computed: {
		btnType() {
			return ['submit', 'reset'].includes(this.type) ? this.type : 'button';
		},
		fieldClass() {
			return `apx-btn ${this.btnStyle ? `apx-btn--${this.btnStyle}` : ''}`;
		},
	}
}

</script>

<style lang="scss">
@import "../../../../sass/views/apex/apex.variables";
@import "../../../../sass/views/apex/form-components/button";
</style>