<template>
	<div class="main-wrapper center">
		<v-alert type="error" class="mb-20" v-if="error">{{ error }}</v-alert>
		<div class="apx-panel p-60">
			<logo> </logo>
			<router-view :style="brandingVars"></router-view>
		</div>
		<div class="quick-links">
			<p>
				<a :href="authGlobals.ssaLink" target="_blank">Software Services Agreement</a>
				<a :href="authGlobals.pnLink" target="_blank">Private Notice</a><br>
				<a :href="authGlobals.supportLink" target="_blank">Contact Support</a>
				<a :href="authGlobals.statusLink" target="_blank">Status Page</a>
			</p>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import Logo from '../../components/apex-ui/v-logo.vue';
import VAlert from "../../components/apex-ui/v-alert.vue";
import branding from "./mixins/branding";
export default {
	name: "MainAuthTemplate",
	mixins: [branding],
	components: {
		Logo,
		VAlert
	},
	computed: {
		...mapState(['authGlobals']),
		error() {
			return this.authGlobals.error || null;
		},
	}
}
</script>

<style lang="scss">
@import "../../../sass/views/apex/apex.variables";
.main-wrapper {

	&.center {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	width: 100%;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;

	.quick-links {
		p {
			text-align: center;
			font-size: 12px;
			margin-top: 18px;
			a {
				padding: 1px 10px;
				border-right: 1px $apx-grey solid;
        			&:nth-child(2) {
          				border-right: none;
        			}
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}
</style>
