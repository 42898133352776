<template>
	<div>
		<h2 class="apx-section-title">{{ $t('titles.enterOrgKey') }}</h2>
		<div class="apx-form-row mb-15">
			<v-input name="organizationKey" v-model="formData.organizationKey" type="password" label="Organization key"/>
		</div>

		<activation-footer :errorResponse="errorResponse">
			<template v-slot:buttons>
				<v-button btnStyle="primary" :disabled="isSubmitting" @click="submit">
					<span v-if="isSubmitting" class="mr-5"><i class="apx-loading"></i></span>
					<span>{{ $t('labels.activate') }}</span>
				</v-button>
			</template>
		</activation-footer>
	</div>
</template>

<script>
	import vInput from '../../../components/apex-ui/form-components/v-input.vue';
	import vButton from '../../../components/apex-ui/form-components/v-button.vue';

	import activationFooter from './activation-footer.vue';

	import GlobalBus from "../../../eventBus";
	import { mapActions } from "vuex";
	import SsoUserActivation from "../../../Model/SsoUserActivation";

	export default {
		name: 'AuthenticationUser',

		props: ['formData'],

		components: {
			vInput,
			vButton,
			activationFooter
		},

		data() {
			return {
				copied: false,
				isSubmitting: false,
				errorResponse: null
			}
		},

		methods: {
			...mapActions(['activateAccount']),
			copyToClipboard() {
				this.$copyText(this.formData.organizationKey).then(() => {
					this.copied = true;
					setTimeout(() => this.copied = false, 3000);
				});
			},
			validate() {
				const errors = {};

				if (!this.formData.organizationKey) {
					errors.organizationKey = this.$t('errors.emptyOrgKey');
				}

				GlobalBus.$emit('apexUI.validation', errors);

				return Object.keys(errors).length === 0;
			},
			submit() {
				if (this.validate()) {
					this.isSubmitting = true;
					this.activateAccount(new SsoUserActivation(this.formData))
						.then(({ data: { redirectUrl = null } }) => {
							if (redirectUrl) {
								window.location.replace(redirectUrl);
							}
						})
						.catch(({ response: { data: { message = '' } } = {} }) => {
							this.errorResponse = message;
						})
						.finally(() => this.isSubmitting = false);
				}
			}
		}
	}
</script>
