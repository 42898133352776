import Vue from "vue";
import Main from "./../main.vue";
import Login from "./../login.vue";
import SSOBlocked from "./../sso_blocked.vue";
import OrganizationKey from "./../organizationKey.vue";
import LoginSelection from "./../loginSelection.vue";
import Activation from "./../activation.vue";
import ViewRouter from "vue-router";

Vue.use(ViewRouter);

const routes = [
	{
		path: "/login/",
		component: Main,
		alias: "/",
		children: [
			{
				path: "",
				name: "login-selection",
				component: LoginSelection,
			},
			{
				path: "sso/organization-key",
				name: "organization-key",
				component: OrganizationKey,
			},
			{
				path: "/login/PP",
				name: "login-with-passportal",
				component: Login,
			},
			{
				path: "/sso/blocked",
				name: "sso-blocked",
				component: SSOBlocked,
			}
		]
	},
	{
		path: "/sso/activate",
		name: "activation",
		component: Activation,
	},
];

export default new ViewRouter({
	routes,
	mode: "history",
});
