<template>
	<div class="form-group">
		<label :for="fieldId" v-if="label" class="apx-form-label" v-text="label"></label>
		<v-select :id="fieldId" v-model="model" @input="error = null" :disabled="disabled" :options="options" :class="error ? 'apx-has-error' : ''">
			<template #open-indicator>
				<div class="v-select--dropdown-toggle-arrow ic_pointer_down"></div>
			</template>
		</v-select>
		<small class="apx-field-error" v-if="error" v-html="error"></small>
		<small :id="`${fieldId}Help`" class="apx-field-help" v-else-if="help" v-html="help"></small>
	</div>
</template>
<script>
import { commonAttributes, hasModel, hasValidation } from "./mixins.js";
import vSelect from "vue-select";

export default {
	mixins: [commonAttributes, hasModel, hasValidation],
	props: {
		options: {
			type: Array,
			required: false,
			default: [],
		}
	},
	components: {
		vSelect
	},
}

</script>

<style lang="scss">
@import "../../../../sass/views/apex/apex.variables";
@import "../../../../sass/views/apex/form-components/select";
</style>