<template>
	<div class="activation">
		<div v-if="authGlobals.errorMessage">
			<v-alert type="error" :close-redirect="route('login.view')"><span v-html="authGlobals.errorMessage"></span></v-alert>
		</div>
		<div v-else-if="!authGlobals.canActivate">
			<v-alert type="error" :dismissible="false">{{ $t('cannotRegister') }}</v-alert>
		</div>
		<div v-else>
			<h1 class="apx-page-title">{{ $t('titles.activation') }}</h1>
			<div class="apx-panel apx-form">
				<div v-if="isOrganizationActivation || isSiteActivation">
					<step1 v-if="wizardStep === 1"
						:formData="formData"
						:btnNextClick="nextStep"
					></step1>

					<step2 v-else-if="wizardStep === 2"
						:formData="formData"
						:btnNextClick="nextStep"
						:btnPrevClick="previousStep"
						@updateRedirectUrl="redirectUrl = $event"
					></step2>

					<step3 v-else-if="wizardStep === 3"
						:formData="formData"
						:redirectUrl="redirectUrl"
					></step3>
				</div>
				<div v-else-if="isUserActivation">
					<user-activation
						:formData="formData"
					></user-activation>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import vAlert from '../../components/apex-ui/v-alert.vue';

import Step1 from './components/activation-step1.vue';
import Step2 from './components/activation-step2.vue';
import Step3 from './components/activation-step3.vue';
import UserActivation from './components/activation-user.vue';

import { mapState } from "vuex";

export default {
	name: 'Authentication',

	components: {
		vAlert,
		Step1,
		Step2,
		Step3,
		UserActivation,
	},

	data() {
		return {
			wizardStep: 1,
			formData: {
				code: null,
				agreed: null,
				orgZip: null,
				orgCity: null,
				orgState: null,
				orgAddress: null,
				orgCountryId: null,
				organizationKey: null,
				confirmOrganizationKey: null,
				encryptVaultKeyPassword: null
			},
			redirectUrl: "/",
		}
	},
	mounted() {
		this.formData.code = this.authGlobals.code;
		this.formData.encryptVaultKeyPassword = this.authGlobals.encryptVaultKeyPassword;
		this.wizardStep = 1;
	},
	computed: {
		...mapState(['authGlobals']),
		isOrganizationActivation() {
			return this.authGlobals.activationType === 'orgRegistration'
		},
		isUserActivation() {
			return this.authGlobals.activationType === 'userActivationWithoutKey'
		},
		isSiteActivation() {
			return this.authGlobals.activationType === 'siteRegistration'
		}
	},
	methods: {
		previousStep() {
			this.wizardStep--;
			if (this.wizardStep < 1) {
				this.wizardStep = 1;
			}
		},
		nextStep() {
			this.wizardStep++;
			if (this.wizardStep > 3) {
				this.wizardStep = 3;
			}
		}
	}
}
</script>
<style lang="scss">
@import "../../../sass/views/apex/apex.variables";
@import "../../../sass/views/apex/apex.form";

.activation {
	width: 100%;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}
</style>